// Only one of both needed.
// CoreUI has sometimes a bit strange styling (colors like pink), so just use
// Bootstrap for styling. Respectively a bootstrap theme from https://bootswatch.com/
//-----------------------------------------------------------------------------
// import "@coreui/coreui/dist/css/coreui.css";
// import "bootstrap/dist/css/bootstrap.css";

// Need to import in style -- or treat this as "sideEffect" (cf. webpack.config.js)
import "../styles/bootstrap-cosmo.css";
//-----------------------------------------------------------------------------
import { App } from "vue";

import {
    CButton,

    CContainer,
    CRow,
    CCol,

    CFormCheck,
    CFormTextarea
} from "@coreui/vue";

import { CIcon } from "@coreui/icons-vue";
import {
    cilCamera
} from "@coreui/icons";
//-----------------------------------------------------------------------------
function registerIcons(app: App): void {
    // Need to import the icon above first!
    const icons = {
        cilCamera
    };

    app.provide("icons", icons);
}
//-----------------------------------------------------------------------------
let registered = false;

export default function setupCoreUI(app: App): void {
    if (registered) {
        return;
    }

    app.component(CIcon.name           , CIcon);

    app.component(CButton.name         , CButton);

    app.component(CContainer.name      , CContainer);
    app.component(CRow.name            , CRow);
    app.component(CCol.name            , CCol);

    app.component(CFormCheck.name      , CFormCheck);
    app.component(CFormTextarea.name   , CFormTextarea);

    registerIcons(app);
    registered = true;

    console.debug("[CoreUI] registered components, directives");
}
