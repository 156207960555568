import { createApp }                                    from "vue";
import App                                              from "./app.vue";
import { ApplicationInsights, DistributedTracingModes } from "@microsoft/applicationinsights-web";
import { APP_INSIGHTS_APP_ID, APP_INSIGHTS_CONN }       from "@const/environment";
//-----------------------------------------------------------------------------
console.debug("[App] starting...");

if (!__DEBUG__) {
    const appInsights = new ApplicationInsights({
        config: {
            connectionString            : APP_INSIGHTS_CONN,
            appId                       : APP_INSIGHTS_APP_ID,
            enableAutoRouteTracking     : true,
            enableCorsCorrelation       : true,
            enableRequestHeaderTracking : true,
            enableResponseHeaderTracking: true,
            distributedTracingMode      : DistributedTracingModes.W3C
        }
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
} else {
    console.debug("[AppInsights] skipped registration");
}

const app = createApp(App);

console.debug("[App] mounting...");
app.mount("#app");
console.debug(`[App] mounted, Vue version: ${app.version}`);
//-----------------------------------------------------------------------------
export { app };
